import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCoach } from '../slices/coachSlice'; // Assurez-vous que ce sélecteur est correctement importé
import { selectLessons } from '../slices/lessonSlice';
import Calendar from '../components/calendar';
import AddLesson from '../components/addLesson';

const Admin = () => {
  const coach = useSelector(selectCoach); // Récupère l'état complet du coach
  const lessons = useSelector(selectLessons);

  const [myLessons, setMyLessons] = useState([]);
  const [isModify, setIsModify] = useState(false);
  const [isPopUp, setIsPopUp] = useState(false);

  const onClickChangeLessons = (type) => {
    switch (type) {
      case 'all':
        setMyLessons(lessons.allLessons);
        break;
      case 'futur':
        setMyLessons(lessons.futurLessons);
        break;
      case 'old':
        setMyLessons(lessons.oldLessons);
        break;
      default:
        setMyLessons(lessons.allLessons);
        break;
    }
  };

  useEffect(() => {
    console.log(myLessons);
  }, [myLessons]);

  return (
    <div>
      <div className="dashboard">
        {!coach.isLogged ? (
          <p>Vous devez vous connecter pour voir les fonctionnalités complètes.</p>
        ) : (
          <div>
            <h2>Bienvenue sur votre espace d'administration CoachMe !</h2>
            <button
              className="orange_button"
              onClick={() => setIsPopUp(true)}
            >
              Ajouter un cours
            </button>
            <button
              className="orange_button"
              onClick={() => onClickChangeLessons('all')}
            >
              Tous mes cours
            </button>
            <button
              className="orange_button"
              onClick={() => onClickChangeLessons('futur')}
            >
              Mes cours à venir
            </button>
            <button
              className="orange_button"
              onClick={() => onClickChangeLessons('old')}
            >
              Mes cours passés
            </button>
          </div>
        )}
      </div>
      <div className='img_background'> 
        <img src="./image2.jpeg" alt="Background" />
      </div>
      {isPopUp && (
        <AddLesson
          onClose={() => setIsPopUp(false)}
        />
      )}
      {myLessons.length > 0 && (
        <Calendar lessons={myLessons} isModify={isModify} />
      )}
    </div>
  );
};

export default Admin;
